$is-codecombat: false;

.modal-container {
  border-radius: 10px;
}
.coco-modal-header {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.13);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
// Turn off extra backdrop shadow
.modal-backdrop.vue-modal {
  display: none;
}
