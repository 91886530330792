$headline-font: 'Arvo', serif
$body-font: 'Open Sans', sans-serif
$pixel-headline-font: "lores12ot-bold", "VT323"

$burgundy: #7D0101
$gold: #F2BE19
$navy: #0E4C60
$forest: #20572B
$teal: #1FBAB4
$teal-dark: #0E4C60
$gray: #232323
$pitch: #131b25
$yellow-dark: #fcbb00
$yellow-light: #fcd200

$wider-breakpoint: 1160px
