$is-codecombat: false
#loading-error
  padding: 20px

  .btn
    margin-top: 20px
  
  .login-btn
    margin-right: 0px
  
  #create-account-btn
    margin-left: 10px
  
  #not-found-img
    max-width: 20%
    margin: 20px 0
    
  #links-row
    margin-top: 50px
