h1
  text-shadow: none
  border-radius: 5px
  
.editor-widget
  width: 100%
  height: 100%
  position: absolute
  top: 0
//  right: 50%
//  bottom: 40px
  left: 0

.centering_container
  position: absolute
  left: 25%
  bottom: 5%
  z-index: 1

#console
  width: 50%
  height: 100%
  position: absolute
  top: 0
  right: 0

#submit
  position: relative
  left: -50%
  z-index: 1

html
  .lt-ie7, .lt-ie8, .lt-ie9, .lt-ie10
    display: none
  &.lt-ie7 .lt-ie7
    display: block
  &.lt-ie8 .lt-ie8
    display: block
  &.lt-ie9 .lt-ie9
    display: block
  &.lt-ie10 .lt-ie10
    display: block
