.ladder-submission-view
  button
    text-shadow: 0px -1px 0px black

  .last-submitted, .help-simulate
    font-size: 14px
    font-weight: normal

  .last-submitted
    float: left

  .help-simulate
    float: right

