$is-codecombat: false
$body-font-style: null
$chromeRightPadding: null
$title-font-style: null
$label-font-style: null
$code-font-style: null
$studentNameWidth: null
$mist: null
$chromeTopPadding: null
$chromeLeftPadding: null
$sun: null
$moon: null
$goldenlight: null
$dusk: null
$fog: null
$dusk-dark: null
$twilight: null
$eve: null
$authGray: null
$chromeBottomPadding: null
$haze: null
$tooltip-spacing: null
$dawn: null
$anti-tooltip-spacing: null
$acodus-glow: null

@import './styles/bootstrap/variables'
// Try switching to bootstrap 4 later
@import './styles/bootstrap/bootstrap.scss'

// create a separate bundle of such vendors using webpack
@import '../bower_components/nanoscroller/bin/css/nanoscroller.css'
@import '~shepherd.js/dist/css/shepherd.css'
@import '../bower_components/treema/treema.css'

@import './styles/common/common.sass'
@import './styles/application'

// TODO: Figure out how to have the static pages have their own little separate CSS bundles (without duping it)
// (there is no CSS file for PrivacyView or LegalView)
@import 'app/styles/home-view.sass'
@import 'app/styles/home-view.scss'
@import 'app/styles/home-cn-view.sass'
@import 'app/styles/play/campaign-view.sass'

// TODO: Put this in the teacher dashboard views
@import 'app/styles/courses/teacher-dashboard-nav.sass'

// These ones weren't moved into separate files
// TODO: Move some of these into individual files where possible (eg style-flat)
@import './styles/common/site-chrome.sass'
@import './styles/contribute/contribute_classes.sass'
@import './styles/editor/component/thang-component-config-view.sass'
@import './styles/editor/delta.sass'
@import './styles/editor/editor.sass'
@import './styles/editor/patches.sass'
@import './styles/forms.sass'
@import './styles/i18n/i18n-edit-model-view.sass'
@import './styles/kinds/search.sass'
@import './styles/mixins.sass'
@import './styles/modal/create-account-modal/school-info-panel.sass'
@import './styles/play/common/ladder_submission.sass'
@import './styles/play/level/hints-view.sass'
@import './styles/play/level/thang_avatar.sass'
@import './styles/play/level/tome/spell_debug.sass'
@import './styles/play/level/tome/spell_toolbar.sass'
@import './styles/play/level/tome/spell_translation.sass'
@import './styles/play/menu/item-view.sass'
@import './styles/play/modal/leaderboard-tab-view.sass'
@import './styles/play/variables.sass'
@import './styles/style-flat-variables.sass'
@import './styles/style-flat.sass'
@import './styles/treema-ext.sass'

// Ozaria common styles
// TODO move these imports to some file in ozaria folder?
@import 'ozaria/site/styles/common/common.scss'
