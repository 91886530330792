.search-view

  a.open-modal-button
    float: right

  input#search
    width: 190px
    height: 30px
    padding-left: 5px

  table.table
    background-color: white
    .body-row
      max-width: 600px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
    .name-row
      @extend .body-row
      max-width: 300px
    .description-row
      @extend .body-row
      max-width: 520px
    .small-name-row
      @extend .body-row
      max-width: 200px
    .watch-row
      @extend .body-row
      max-width: 80px
      text-align: center
      &.watching
        opacity: 1.0
      &.not-watching
        opacity: 0.5

    tr.mine
      background-color: #f8ecaa

  .loading
    text-align: center

  .progress
    margin: 0 20%
    width: 60%

  .progress-bar
    width: 100%
