$is-codecombat: false
@import "app/styles/style-flat-variables"
@import "ozaria/site/styles/common/variables"

#basic-info-view
  @if $is-codecombat
    .network-login
      transition: filter 0.1s linear

      &:hover
        filter: brightness(110%)

  @else
    .auth-network-logins
      display: flex
      align-items: center
      justify-content: center
      flex-direction: row

  .gplus-login
    @if $is-codecombat
      margin-bottom: 20px

    .gplus-logo
      @if $is-codecombat
        width: 254px
      @else
        height: 50px
        margin-right: 7px

  .facebook-login

    #facebook-signup-btn
      height: 40px

      .facebook-logo
        height: 40px

  @if $is-codecombat
    .clever-login

      .clever-logo
        // Match size of Google button
        width: 254px
        height: 52px

  // Forms

  .basic-info
    display: flex
    flex-direction: column

    .form-group
      text-align: left
      margin: 0

      &[dir="rtl"]
        text-align: right

      input
        @if $is-codecombat
          max-height: 5vh
        @else
          max-height: 6vh
          border: 1px solid $teal
          color: $color-tertiary-brand

      .help-block
        float: right

      .fancy-error
        padding-top: 29px
        line-height: 14px

    .form-container
      > .form-group, > .row
        max-height: 84px
        max-height: calc(29px + 29px + 5vh)
        flex-grow: 1
        align-self: flex-start

  .btn-illustrated img
    // Undo previous opacity-toggling hover behavior
    opacity: 1

  label
    margin-bottom: 0

  .help-block
    margin: 0

  .optional-help-block
    font-style: italic

  .form-container
    width: 800px

  .input-lg
    padding-top: 0
    padding-bottom: 0
    padding-left: 12px
    padding-right: 12px

  .optional-text
    font-size: 12px

  #want-in-school
    position: absolute
    bottom: 18px

    #want-in-school-checkbox
      margin-top: 7px

  @if not $is-codecombat
    .row.or-row
      margin-top: 20px

  .library-signup-text
    text-align: center
    padding-bottom: 10px
    font-weight: lighter
