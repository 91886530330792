.contribute_class  

  #homepage_screenshot
    margin: 20px 0px

  .class_detail
    float: left

    img
      width: 360px

  .signature
    text-align: right 
    
  a
    cursor: pointer  

  .class_image
    float: left
    width: 150px
    margin: 10px 10px 20px 20px
    
  .class-main
    margin-left: 33%
    padding: 0px 40px 40px 40px
    box-sizing: border-box        

    .header-scrolling-fix
      // Padding: so that the anchors get scrolled properly below the top bar.
      padding-top: 65px
      // Margin: so that there isn't a huge space between classes
      margin-top: -50px
      
    .well
      padding: 5px 5px 5px 30px

    .saved-notification
      display: none
      float: right

  .thumbnail
    background-color: transparent
    height: 176px
    &:hover
      background-color: rgba(200, 244, 255, 0.2)

    a:not(.has-github)
      cursor: default
      text-decoration: none

    img
      max-width: 100px
      max-height: 100px
    .caption
      background-color: transparent
      h4
        text-align: center
    
