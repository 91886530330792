//
// Grid system
// --------------------------------------------------

// Set the container width, and override it for fixed navbars in media queries
.container {
  @include container-fixed();

  @media (min-width: $screen-sm) {
    width: $container-sm;
  }
  @media (min-width: $screen-md) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

// mobile first defaults
.row {
  @include make-row();
}

// Common styles for small and large grid columns
@include make-grid-columns();


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include make-grid-columns-float(xs);
@include make-grid($grid-columns, xs, width);
@include make-grid($grid-columns, xs, pull);
@include make-grid($grid-columns, xs, push);
@include make-grid($grid-columns, xs, offset);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  @include make-grid-columns-float(sm);
  @include make-grid($grid-columns, sm, width);
  @include make-grid($grid-columns, sm, pull);
  @include make-grid($grid-columns, sm, push);
  @include make-grid($grid-columns, sm, offset);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  @include make-grid-columns-float(md);
  @include make-grid($grid-columns, md, width);
  @include make-grid($grid-columns, md, pull);
  @include make-grid($grid-columns, md, push);
  @include make-grid($grid-columns, md, offset);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  @include make-grid-columns-float(lg);
  @include make-grid($grid-columns, lg, width);
  @include make-grid($grid-columns, lg, pull);
  @include make-grid($grid-columns, lg, push);
  @include make-grid($grid-columns, lg, offset);
}

