@import "app/styles/bootstrap/variables";
@import "app/styles/mixins";
@import "app/styles/style-flat-variables";
@import "ozaria/site/styles/common/common.scss";
@import "app/styles/ozaria/_ozaria-style-params.scss";


@mixin fluid-font-size($font-size: 12px, $line-height: 18px, $max-multiplier: 1.3) {
  $wide-screen: 1366px;
  font-size: $font-size;
  line-height: $line-height;

  @media screen and (min-width: $wide-screen){
    font-size: min($font-size * $max-multiplier, $font-size/$wide-screen*100vw);
    line-height: min($line-height * $max-multiplier, $line-height/$wide-screen*100vw);
  }
}

@if not $is-codecombat {
  #home-page {
    margin-bottom: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    h1, h2, h3, p {
      font-family: Work Sans, "Open Sans", sans-serif;
    }

    h1 {
      font-weight: 600;
      font-style: normal;
      @include fluid-font-size(46px, 56px);
      color: black;
      word-break: keep-all
    }

    h1.smaller-38 {
      font-size: 38px;
      line-height: 45px;
    }

    h1.heading-corner {
      background: url(/images/ozaria/home/heading_corner.svg) no-repeat left 0 top 0;
      background-size: 48px;
      padding: 12px 0 12px 20px;
    }

    h2 {
      font-style: normal;
      font-weight: 600;
      @include fluid-font-size(28px, 38px);
      letter-spacing: 0.56px;
    }

    .subtitle-mid {
      max-width: 67%;
    }

    p {
      font-style: normal;
      @include fluid-font-size(24px, 30px);
      letter-spacing: 0.444px;
      color: black;
    }

    blockquote {
      font-family: "Space Mono";
      font-weight: 400;
      font-style: normal;
      @include fluid-font-size(24px, 30px);
    }

    blockquote footer {
      font-weight: 700;
      @include fluid-font-size(16px, 24px);
    }

    .row.flex-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }


    .tree-art-asset {
      position: absolute;
      left: -90px;
      top: -5%;
    }
    .frame-art-asset {
      position: absolute;
      right: 0;
      top: 17%;
    }
    .chris-image {
      transform: translateY(-40%);
    }
    .code-graphic-mobile {
      display: none;
    }

    @media (min-width: 769px) {
      #speech-bubble-testimonial-1 {
        .crystal-art {
          transform: translate(-25%,-50%);
          z-index: 1;
          img{
            max-width: 170px;
          }
        }
      }
      #global-community .tree-art-asset {
        left: -90px;
        img{
          width: 60%;
        }
      }

      #global-community .frame-art-asset img{
        width: 60%;
      }
    }

    @media (min-width: 1200px) {
      #global-community .tree-art-asset img{
        width: 100%;
      }
      #global-community .frame-art-asset img{
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      .tree-art-asset {
        top: 5%;
        left: -40px;
        width: 160px;
      }
      .frame-art-asset {
        top: 6%;
        right: -40px;
        width: 160px;
        img {
          max-height: 100px;
        }
      }
      #speech-bubble-testimonial-1 {
        margin-bottom: -10%;
        .crystal-art {
          z-index: 1;
          width: 100%;
          position: absolute;
          transform: translate(-10%, -100%);
          img{
            max-width: 100px;
          }
        }
        blockquote {
          font-size: 20px;
        }
      }
      #speech-bubble-testimonial-2 {
        .crystal-art {
          position: absolute;
          right: 15%;
          bottom: 12%;
          img{
            max-width: 100px;
          }
        }
        blockquote {
          font-size: 20px;
        }
      }
      .chris-image {
        transform: translateY(-20%);
      }
      .subtitle-mid {
        max-width: 100%;
      }
      .section-spacer{
        padding-bottom: 130px;
      }
      .control-buttons {
        position: absolute;
        width: 90%;
        left: 5%;
      }
      .sm-mt-50 {
        margin-top: 50px;
      }
      .code-graphic {
        display: none;
      }
      .code-graphic-mobile {
        display: block;
        margin-right: 70px;
      }
      #graphics-1 .code-graphic-mobile {
        width: 45%;
      }
      #graphics-1 {
        padding-top: 10px;
      }
      .xs-marg-15 {
        margin: 0 15px;
      }
    }

    @media screen and (max-width: 768px) {
      .row.flex-row {
        display: table;
      }
    }

    background: linear-gradient(277.08deg, #FFF5D1 2.71%, #FFFFFF 41.36%);

    .btn-primary.btn-moon {
      background-color: $moon;
      border-radius: 1px;
      color: $gray;
      text-shadow: unset;
      font-weight: bold;
      @include font-h-5-button-text-black;
      min-width: 260px;
      padding: 15px 0;

      @include fluid-font-size($font-h-5-button-text-white-font-size, $font-h-5-button-text-white-line-height, 1.5);
      @media screen and (min-width: 1366px) {
        min-width: 300px;
        padding: 15px 20px;
      }

      &:hover {
        @include font-h-5-button-text-white;
        @include fluid-font-size($font-h-5-button-text-white-font-size, $font-h-5-button-text-white-line-height, 1.5);
        background-color: $goldenlight;
        transition: background-color .35s;
      }
    }

    .btn-primary-alt, .btn-navy-alt, .btn-teal-alt {
      background-color: transparent;
      border: 2px solid $teal;
      color: $teal;
      border-radius: 4px;
      text-shadow: unset;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.71px;
      line-height: 24px;
      width: 247px;
      &:hover {
        background-color: $teal;
        box-shadow: unset;
        color: $gray;
        transition: color .35s, background-color .35s, box-shadow .35s;
      }
    }

    .ozaria-secondary-btn {
      background-color: $dusk;
      color: $pitch;
      border-radius: 1px;
      text-transform: unset;
      min-width: 260px;
      padding: 15px;
      line-height: 30px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.4px;
      text-shadow: none;

      &:hover {
        background-color: $dusk-dark;
        color: $white;
        transition: color .35s, background-color .35s, box-shadow .35s;
      }
    }

    #top-banner {
      background-color: $teal-light-2;
      padding: 10px 0;
      text-align: center;
      overflow: hidden;

      a {
        margin-left: 10px;
        font-weight: 700;
        color: #000;
      }

      span {
        p {
          margin: 0;
          font-size: unset
        }
      }
    }

    // Most sections have a max width and are centered.
    & > section {
      max-width: 1820px;
      width:100%;
      padding: 0 70px;
      position: relative;
      z-index: 1;
    }

    @media screen and (max-width: 768px) {
      & > section {
        padding: 0 20px;
      }
    }

    // This lets us have full width sections easily.
    section.full-width {
      max-width: unset;
      padding: 0;
      margin: 0;
    }

    #jumbotron-container-fluid {
      position: relative;
      margin-top: 55px;
      padding-bottom: 36px;
      background-image: url(/images/ozaria/home/hero_image.png);
      @supports (background-image: url(/images/ozaria/home/hero_image.webp)) {
        background-image: url(/images/ozaria/home/hero_image.webp)
      }
      background-repeat: no-repeat;
      background-position: bottom 0 right 33px;

      background-size: 65%;
      background-size: unquote('min(558px, 65%)');
      img {
        width: 250px;
        height: auto;
        margin-bottom: 20px;
      }
      h2 {
        margin-bottom: 40px;
        font-weight: 400;
      }
      p, a, button {
        margin-bottom: 20px;
      }
      @media screen and (max-width: 768px) {
        // Adds space between image and text on mobile
        & {
          padding-bottom: 50%;
        }
        .item-list{
          text-align: center;
        }
      }
      @media screen and (max-width: 1300px) {
        background-size: 50%;
        background-size: unquote('min(450px, 50%)');
      }
    }

    #graphics-1 {
      pointer-events: none;

      .move-image {
        display: flex;
        justify-content: end;
        justify-content: flex-end;
        width: 100%;
        transform: translate(-30%, -26%);
        transform: translate(unquote('max(-360px, -30%)'), -26%);
        @media screen and (max-width: 1300px) {
          transform: translate(unquote('max(-360px, -24%)'), -26%);
        }
      }

      img {
        width: 45%;
        height: auto;
        width: unquote('min(505px, 45vw)');
        height: unquote('min(340px, 30.2vw)');
      }
    }

    #graphics-1-back {
      background: url(/images/ozaria/home/graphic_1_background.svg) no-repeat center;
      background-size: cover;
      width: 100vw;
      height: 45vw;
      margin-bottom: -45vw;
      transform: translateY(-48%);
    }

    .row.video-row {
      margin-bottom: 90px
    }

    .video-container {
      position: relative;
      padding-top: 56.25%;
      box-shadow: 0 0 30px $teal;
    }

    #back_cta_1, #back_cta_2 {
      background-image: url(/images/ozaria/home/background_cta_1.png);
      @supports (background-image: url(/images/ozaria/home/background_cta_1.webp)) {
        background-image: url(/images/ozaria/home/background_cta_1.webp)
      }
      background-repeat: no-repeat;
      background-position: center;

      background-size: cover;
      height: 56.4vw;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    #back_cta_2 {
      background-image: url(/images/ozaria/home/background_cta_2.png);
      @supports (background-image: url(/images/ozaria/home/background_cta_2.webp)) {
        background-image: url(/images/ozaria/home/background_cta_2.webp)
      }
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    section#engaging {
      @media screen and (max-width: 768px) {
        // Adds space between image and text on mobile
        .row > div {
          padding-top: 32px;
        }
      }
    }

    #speech-bubble-testimonial-1, #speech-bubble-testimonial-2 {
      blockquote {
        border-image: url(/images/ozaria/home/bubble1_down.svg);
        border-width: 40px;
        border-image-slice: 60 50 67 60;
        border-style: solid;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      footer {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        text-align: right;
      }

      blockquote::before {
        content: none !important;
      }
    }

    #speech-bubble-testimonial-2 {
      blockquote {
        border-image: url(/images/ozaria/home/bubble2_down.svg);
        border-width: 25px 30px 70px;
        border-image-slice: 40 40 110;
        border-style: solid;
      }
      .crystal-art {
        z-index: 1;
        max-width: 170px;
        transform: rotate(180deg) translate(-60%,60%);
        float: right;
      }
    }

    @media screen and (max-width: 768px) {
      #speech-bubble-testimonial-1 blockquote {
        border-image-source: url(/images/ozaria/home/bubble1_down_mobile.svg)
      }

      #speech-bubble-testimonial-2 blockquote {
        border-image-source: url(/images/ozaria/home/bubble2_down_mobile.svg)
      }
    }

    #core-curriculum {
      margin-bottom: 147px;

      a {
        margin: 20px;
        padding: 10px;
        min-width: 320px;
      }

      .flex-row {
        justify-content: center;
      }
    }

    #global-community {
      margin-bottom: 150px;

      h1 {
        text-align: center;
        margin-bottom: 40px;
      }

      p {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 0;
      }

      .row {
        padding: 30px;
        border: 10px solid;
        border-image-source: linear-gradient(90deg, rgba(50,114,219,1) 0%, rgba(82,178,209,1) 55%, rgba(124,238,201,1) 100%);
        border-image-slice: 1;
      }

      img {
        height: 90px;
        width: auto;
        margin-bottom: 6px;
      }

      .row > div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 768px) {
        .row > div {
          padding-top: 32px;
        }
        margin-bottom: 0px;
      }
    }

    #admin-dashboard {
      p {
        font-size: 20px;
      }

      p.color-change {
        color: white;
        background-color: #476FB1;
        border: 10px solid #476FB1;
        margin: -10px;
      }

      .row.flex-row {
        // Needs to be padding to background image uses it.
        padding: 115px 0;
      }

      .row.flex-row:last-of-type {
        justify-content: center;
        padding: 0 0 200px 0;
      }

      .row.flex-row:first-of-type {
        background: url(/images/ozaria/home/admin_background_blue.svg) no-repeat right 0;
        background-size: contain;
      }

      .row.flex-row:nth-of-type(2) {
        background: url(/images/ozaria/home/admin_background_yellow.svg) no-repeat left 0;
        background-size: contain;
      }

      @media screen and (max-width: 768px) {
        .row.flex-row:first-of-type, .row.flex-row:nth-of-type(2) {
          background: unset;
        }

        p, p.color-change {
            margin: 20px 0 0 0;
        }

        .row.flex-row {
          padding: 85px 0 0 0;
        }
        .row.flex-row:last-child {
          padding: 85px 0;
        }
      }
    }

    #core-curriculum-carousel {
      // TODO - slight shake on moving carousel items, height changes.
      border: 10px solid;
      margin: 0 -70px -40px;
      padding: 40px 70px;
      pointer-events: none;
      z-index: -1;
      border-image-source: linear-gradient(90deg, rgba(50,114,219,1) 0%, rgba(82,178,209,1) 55%, rgba(124,238,201,1) 100%);
      border-image-slice: 1;
      h2 {
        margin-top: 18px;
        margin-bottom: 20px;
      }
    }

    .control-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 5px 0 70px;
      z-index: 1;

      button.press-engage, button.press-explore, button.press-explain, button.press-elaborate, button.press-evaluate {
        background-image: url(/images/ozaria/home/carousel/button_1.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 60px;
        width: 248px;
        border: none;
        -webkit-appearance: none;
        background-color: unset;
      }
      button.press-engage.active {
        background-image: url(/images/ozaria/home/carousel/button_1_hover.svg);
      }

      button.press-explore {
        background-image: url(/images/ozaria/home/carousel/button_2.svg);
      }
      button.press-explore.active {
        background-image: url(/images/ozaria/home/carousel/button_2_hover.svg);
      }
      button.press-explain {
        background-image: url(/images/ozaria/home/carousel/button_3.svg);
      }
      button.press-explain.active {
        background-image: url(/images/ozaria/home/carousel/button_3_hover.svg);
      }
      button.press-elaborate {
        background-image: url(/images/ozaria/home/carousel/button_4.svg);
      }
      button.press-elaborate.active {
        background-image: url(/images/ozaria/home/carousel/button_4_hover.svg);
      }
      button.press-evaluate {
        background-image: url(/images/ozaria/home/carousel/button_5.svg);
      }
      button.press-evaluate.active {
        background-image: url(/images/ozaria/home/carousel/button_5_hover.svg);
      }
    }

    #engaging {
      .flex-row:nth-child(odd) {
        padding: 100px 0;
      }
    }

    #acodus-awards {
      background: url(/images/ozaria/home/acodus_awards_back.svg) no-repeat;
      background-size: 75%;
      background-position: top center;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .acodus-transform {
        transform: translateY(20%);
        .img-responsive {
          max-height: 710px;
        }
      }

      @media screen and (max-width: 768px) {
        & {
          background: unset;
        }

        .acodus-transform {
          transform: unset;
          padding-bottom: 20px;
        }
      }
    }

    #faq {
      p {
        margin-bottom: 40px;
      }
    }

    @media screen and (max-width: 768px) {
      #core-curriculum-carousel {
        margin: 0 -20px -40px;
      }
    }

    .update-account-blurb {
      max-width: 600px;
    }
  }

  /* Global style to override style-flat footer gap */
  .style-flat div#footer {
    margin-top: 0;
  }
}
