$is-codecombat: false

@import "app/styles/bootstrap/variables"
@import "app/styles/mixins"
@import "app/styles/style-flat-variables"

footer#site-footer
  background-color: $navy
  color: white
  padding-top: 20px
  margin-top: 50px

  @media print
    display: none

  .small
    font-family: $body-font
    font-weight: normal
    font-size: 14px
    line-height: 19px
    letter-spacing: 0.58px

  .text-h3
    color: $teal
    font-family: Arvo
    font-size: 24px
    font-weight: bold
    letter-spacing: 0.48px
    line-height: 30px
    margin: 20px auto
    display: block
    font-variant: normal

  li
    font-family: "Open Sans"
    font-size: 18px
    letter-spacing: 0.75px
    line-height: 26px
    font-weight: 200

  .col-lg-3
    padding-bottom: 15px
    @media (max-width: $screen-md-min)
      padding-left: 27px

    &.shrunken-empty-column
      margin-right: -12.5%

  @media (max-width: $screen-sm-min)
    background-color: #201a15
    background-image: none
    height: auto

  a
    color: white

  .social-buttons > a
    margin-right: 10px

  .small
    color: rgba(255,255,255,0.8)

  .mpqr
    width: 95px

  .follow_us
    display: flex
    flex-direction: column
    .socialicon
      display: flex
      justify-content: space-between
      .si
        width: 50px
        height: 45px
        background-size: 50px
        background-position: center
        background-repeat: no-repeat
        position: relative
        cursor: pointer
      .si-bilibili
        background-image: url('https://assets.koudashijie.com/images/home/icon/bilibili-dark.png')
        &:hover
          background-image: url('https://assets.koudashijie.com/images/home/icon/bilibili-light.png')
      .si-wechat
        background-image: url('https://assets.koudashijie.com/images/home/icon/wechat-dark.png')
        &:hover
          background-image: url('https://assets.koudashijie.com/images/home/icon/wechat-light.png')
        &:hover .mpqrcode
          display: flex
      .si-tiktok
        background-image: url('https://assets.koudashijie.com/images/home/icon/tiktok-dark.png')
        &:hover
          background-image: url('https://assets.koudashijie.com/images/home/icon/tiktok-light.png')
        &:hover .tkqrcode
          display: flex
      .si-weibo
        background-image: url('https://assets.koudashijie.com/images/home/icon/weibo-dark.png')
        &:hover
          background-image: url('https://assets.koudashijie.com/images/home/icon/weibo-light.png')

    .tkqrcode
      display: none
      position: absolute
      top: 50px
      left: 0
      .tkqr
        width: 120px
    .mpqrcode
      display: none
      position: absolute
      top: 50px
      left: 0
      .span
        margin-right: 20px
        display: flex
        flex-direction: column
        align-items: center

  .footer-links
    &__row
      display: flex
      flex-wrap: wrap
      margin-left: -15px
      margin-right: -15px
      justify-content: space-between
    &__col
      padding-left: 15px
      padding-right: 15px
      max-width: 25%
      @media (max-width: $screen-sm-min)
        flex: 0 0 50%
        max-width: 50%
      @media (max-width: $screen-xs-min)
        flex: 0 0 100%
        max-width: 100%

  #final-footer
    padding: 20px 70px 14px
    color: rgba(255,255,255,0.8)
    background-color: $navy
    font-size: 14px

    .float-right
      padding-top: 15px
      float: right

    @media (max-width: $screen-md-min)
      position: inherit
      height: auto
      .float-right
        float: none

    @media (max-width: $screen-sm-min)
      background-color: #201a15

    a
      color: rgba(255,255,255,0.8)
      margin-left: 20px

    img
      height: 40px
      margin-right: 20px

    img#mps
      height: 1em
      margin-right: 0

    .small
      color: rgba(255,255,255,0.8)

    .contact
      margin-right: 20px

  .hover-link
    cursor: pointer

footer#site-footer.dark-mode
  /*background-color: #0C1016*/

