@import "app/styles/mixins"
@import "app/styles/bootstrap/variables"

.spell-toolbar-view
  position: relative
  box-sizing: border-box
  margin: 4px 1%
  height: 45px
  width: 97%
  z-index: 4
  //background-color: rgba(100, 45, 210, 0.15)

  .flow
    &:hover .spell-progress
      opacity: 1

    .spell-progress
      position: absolute
      height: 100%
      top: -50px
      width: 65%
      width: -webkit-calc(85% - 60px)
      width: calc(85% - 60px)
      left: 10%
      display: inline-block
      cursor: pointer
      box-sizing: border-box
      opacity: 0.25
  
      .progress
        position: absolute
        left: 0px
        top: 17.5px
        bottom: 0px
        width: 100%
        height: 4px
        overflow: visible
        pointer-events: none
  
        .progress-bar
          @include transition(width .0s linear)
          position: relative
          pointer-events: none
          background: linear-gradient(#2c3e5f, #2c3e5f 16%, #3a537f 16%, #3a537f 83%, #2c3e5f 84%, #2c3e5f)
          width: 50%
          pointer-events: none
  
          .scrubber-handle
            position: absolute
            pointer-events: none
            right: -5px
            top: -17.5px
            background: linear-gradient(#2c3e5f, #2c3e5f 16%, #3a537f 16%, #3a537f 83%, #2c3e5f 84%, #2c3e5f)
            width: 14px
            height: 40px
            border-radius: 3px
            box-sizing: border-box
            border: 1px solid black
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5)
  
    &:hover .steppers
      opacity: 1

    .steppers
      position: absolute
      z-index: 2
      width: 60px
      top: -50px
      right: 2%
      box-sizing: border-box
      opacity: 0.25
  
      button
        box-sizing: border-box
        height: 40px
        width: 30px
        padding: 2px
  
    .metrics
      display: none
      top: 0px
      position: absolute
      z-index: 10
      pointer-events: none
      border-style: solid
      border-image: url(/images/level/popover_border_background.png) 16 12 fill round
      padding: 0
      border-width: 16px 12px
      font-variant: small-caps
      text-overflow: ellipsis
      font-size: 13px
      white-space: nowrap

html.no-borderimage
  .spell-toolbar-view
    .metrics
      top: 30px
      border: 0
      background: transparent url(/images/level/popover_background.png)
      background-size: 100% 100%
      padding: 10px 20px
