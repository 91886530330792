@import "app/styles/mixins"

.hints-view
  position: relative

  width: 500px // TODO: should be in sync with surface min-width
  padding: 10px 20px
  border-style: solid
  border-image: url(/images/level/popover_border_background.png) 16 12 fill round
  border-width: 16px 12px
  @include box-shadow(0 0 0 #000)
  @include user-select(initial)

  .close-hint-btn
    position: absolute
    right: 5px
    top: 5px

    .glyphicon-remove
      position: relative
      top: 4px

  h1
    margin-bottom: 30px

  .btn-area
    margin-top: 5px

  .hint-title
    font-size: 18px
    text-transform: uppercase

  .hint-body
    height: 80%
    overflow-y: auto
    img
      width: 100%

  .hint-pagination
    font-size: 18px
    margin-top: 0px
    text-transform: uppercase

  pre
    box-sizing: content-box 
    padding: 0
    @include user-select(none)
    .ace_content
      .ace_marker-layer
        .ace_bracket
          display: none
        .ace_selection
          display: none
      .ace_cursor-layer
        .ace_cursor
          display: none
