$is-codecombat: false

@import "app/styles/bootstrap/variables"
@import "app/styles/mixins"
@import "app/styles/style-flat-variables"

#final-footer
  padding: 20px 70px 14px
  color: rgba(255,255,255,0.8)
  background-color: $navy
  font-size: 14px

  .float-right
    padding-top: 15px
    float: right

  @media (max-width: $screen-md-min)
    position: inherit
    height: auto
    .float-right
      float: none

  @media (max-width: $screen-sm-min)
    background-color: #201a15

  a
    color: rgba(255,255,255,0.8)
    margin-left: 20px

  img
    height: 40px
    margin-right: 20px

  img#mps
    height: 1em
    margin-right: 0

  .small
    color: rgba(255,255,255,0.8)

  .contact
    margin-right: 20px
