.i18n-edit-model-view
  .missing-identifier
    border: 2px dashed red
    font-size: 1.2em

  #patch-submit
    margin-top: 5px
  
  .translation-table td
    width: 40%

  .outer-content
    padding: 10px
    overflow-y: scroll
    
  select
    margin-bottom: 10px

  td.accepted
    background: lightgreen
    
  td.rejected, td.withdrawn
    background: pink
    
  td.pending
    background: lightyellow
