.item-mixin, .item-view
  width: 100%

  img
    float: left
    width: 50px
    height: 50px
    margin-right: 5px
    
  .item-info
    float: left
    width: 110px
    width: -webkit-calc(100% - 50px - 5px)
    width: calc(100% - 50px - 5px)

  ul
    margin-top: 5px

  .status-message span
    display: none
