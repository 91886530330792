$is-codecombat: false
@import "app/styles/style-flat-variables"

#confirmation-view
  text-align: left

  .confirm_container
    width: 100%

  .signup-info-box-wrapper
    width: 100%

  .signup-info-box
    padding: 10px 20px

    .product-ozaria &
      border: 2px solid $teal
      p
        margin: 0
    .product-codecombat &
      border: 2px dashed $burgundy

  .nces-search-input-component
    padding-top: 10px
    width: 100%

  .modal-body-content
    width: 80%
    margin-left: 10%
