$is-codecombat: false

.modal-body
  min-width: 746px
  min-height: 520px
  background: linear-gradient(0deg, #fdffff -1.56%, #D7EFF2 45%,  #D7EFF2 55%, #FDFFFF 95.05%)
  display: flex
  flex-direction: column
  align-items: center

.title
  font-size: 24px
  font-weight: bold
  position: relative

.content
  margin-top: 15px

.see-more
  position: absolute
  bottom: 10px
