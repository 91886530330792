.treema-root
  border: 1px solid black
  background-color: #f5f5f5
  padding: 10px 20px 10px 0
  line-height: 1.428571429
    
.treema-markdown
  border: 1px solid gray
  padding: 5px

  &, & > div.ace_editor
    width: 100%
    min-height: 48px
    
  .buttons button
    float: left
    margin-bottom: 5px
    margin-right: 5px
    
  .preview
    clear: both
    width: 100%
    border: 3px inset rgba(0, 100, 100, 0.2)
    box-sizing: border-box
    padding: 5px
    
.treema-selection-map
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 9001
  background: #ffffff
  width: 80%
  height: 80%
  margin: 10%
  box-sizing: border-box 
  border: 2px solid black
  
  img
    position: absolute
    top: 0
    bottom: 0
    margin: auto
    left: 0
    right: 0
    cursor: pointer
    max-width: 95%
    max-height: 95%

.treema-sound-file
  .dropdown li
    margin: 0 10px
    cursor: pointer
    &:hover
      background-color: #dddddd
