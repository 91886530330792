// Cerulean 2.3.1
// Bootswatch
// -----------------------------------------------------

// SCAFFOLDING
// -----------------------------------------------------

// NAVBAR
// -----------------------------------------------------

.navbar {  

  .brand {
    padding: 14px 20px 16px;
    font-family: $headings-font-family;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  }

  li {
    line-height: 20px;
  }

  .nav > li > a {
    padding: 16px 10px 14px;
    font-family: $headings-font-family;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  }

  .search-query {
    border: 1px solid darken($link-color, 10%);
    line-height: normal;
  }

  .navbar-text {
    padding: 19px 10px 18px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.5);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  }

}

.navbar-inverse {

  .navbar-search .search-query {
    color: $text-color;
  }
}

@media (max-width: $grid-float-breakpoint) {

  .navbar .nav-collapse {

    .navbar-nav li > a {

      font-family: $headings-font-family;
      font-weight: normal;
      color: $white;
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);

      &:hover {
      }
    }

    .nav .active > a {
      @include box-shadow(none);
      background-color: #2B7CAC;
    }

    .dropdown-menu li > a:hover,
    .dropdown-menu li > a:focus,
    .dropdown-submenu:hover > a {
      background-image: none;
    }

    .navbar-form,
    .navbar-search {
      border: none;
    }

    .nav-header {
      color: #2B7CAC;
    }
  }

  .navbar-inverse .nav-collapse {

    .nav li > a {
      color: $navbar-inverse-color;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .nav .active > a,
    .nav > li > a:hover,
    .dropdown-menu a:hover {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
}

div.subnav {

  font-family: $headings-font-family;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.2);

}

div.subnav-fixed {
  top: $navbar-height + 1;
}


// NAV
// -----------------------------------------------------

// BUTTONS
// -----------------------------------------------------

.btn {
  @include gradient-vertical-three-colors($white, $white, 5%, darken($white, 0%));
  $shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  @include box-shadow($shadow);

  &:hover {
    background-position: 0 0;
  }
}

//The following were oring buttonBackground

.btn-primary {
  @include button-background(lighten($btn-primary-bg, 5%), $btn-primary-bg);
}

.btn-info {
  @include button-background(lighten($btn-info-bg, 5%), $btn-info-bg);
}

.btn-success {
  @include button-background(lighten($btn-success-bg, 5%), $btn-success-bg);
}

.btn-warning {
  @include button-background(lighten($btn-warning-bg, 5%), $btn-warning-bg);
}

.btn-danger {
  @include button-background(lighten($btn-danger-bg, 5%), $btn-danger-bg);
}

.btn-inverse {
  @include button-background(lighten($btn-default-color, 5%), $btn-default-color);//this should be btn-inverse but I can't find it at the moment
}

// TABLES
// -----------------------------------------------------

// FORMS
// -----------------------------------------------------

// DROPDOWNS
// -----------------------------------------------------

// ALERTS, LABELS, BADGES
// -----------------------------------------------------

// MISC
// -----------------------------------------------------

//i[class^="icon-"] {
//  opacity: 0.8;
//}

// MEDIA QUERIES
// -----------------------------------------------------
